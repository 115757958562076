<template>
  <div>
    <v-snackbar v-model="snackbar" color="dark">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> 닫기 </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AlertBar",

  data() {
    return {
      snackbar: false,
      message: "",
    };
  },

  computed: {
    ...mapGetters({
      msg: "alert/activeMessage",
      active: "alert/active",
    }),
  },

  watch: {
    msg(v) {
      this.message = v;
    },

    active(v) {
      this.snackbar = v;
    },
  },
};
</script>
