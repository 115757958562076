import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",

  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/authenticated"]) {
          //이미 로그인된 상태에서 로그인 페이지로 가는 경우
          return next({ name: "home" });
        }
        next();
      },
    },

    /**
     * 팅서치 - 유저관리
     */
    //유저 (삭제예정)
    {
      path: "/tingsearch/user",
      name: "UserView",
      component: () => import("@/views/tingsearch/UserView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //유저리스트
    {
      path: "/tingsearch/user/all",
      name: "UserList",
      component: () => import("@/views/tingsearch/user/UserList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //해지리스트
    {
      path: "/tingsearch/user/delete-plan",
      name: "DeletePlanList",
      component: () => import("@/views/tingsearch/user/DeletePlanList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //탈퇴리스트
    {
      path: "/tingsearch/user/delete-account",
      name: "DeleteAccountList",
      component: () => import("@/views/tingsearch/user/DeleteAccountList.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    /**
     * 팅서치 - 통계
     */
    //매출통계
    {
      path: "/tingsearch/stat/sales",
      name: "SalesStat",
      component: () => import("@/views/tingsearch/statistics/SalesStat.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //접근통계
    {
      path: "/tingsearch/stat/access",
      name: "AccessStat",
      component: () => import("@/views/tingsearch/statistics/AccessStat.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //검색키워드통계
    {
      path: "/tingsearch/stat/keyword/search",
      name: "KeywordSearchStat",
      component: () =>
        import("@/views/tingsearch/statistics/KeywordSearchStat.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //뉴스키워드통계
    {
      path: "/tingsearch/stat/keyword/news",
      name: "KeywordNewsStat",
      component: () =>
        import("@/views/tingsearch/statistics/KeywordNewsStat.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    /**
     * 팅서치 - 가맹사업 정보
     */
    //가맹사업 정보
    {
      path: "/tingsearch/franchise",
      name: "FranchiseView",
      component: () => import("@/views/tingsearch/FranchiseView.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    /**
     * 파트너스 - 파트너스
     */
    //파트너 리스트
    {
      path: "/partners/member/list",
      name: "MemberList",
      component: () => import("@/views/partners/member/MemberList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //파트너 리스트 개별 상세
    {
      path: "/partners/member/list/detail/:id",
      name: "MemberDetail",
      component: () => import("@/views/partners/member/MemberDetail.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //파트너 리스트 개별 통계내역
    {
      path: "/partners/member/list/stat/:id",
      name: "MemberStat",
      component: () => import("@/views/partners/member/MemberStat.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //링크관리
    {
      path: "/partners/member/link",
      name: "ManageLink",
      component: () => import("@/views/partners/member/ManageLink.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    /**
     * 파트너스 - 통계관리
     */
    {
      path: "/partners/statistics/manage",
      name: "ManageStat",
      component: () => import("@/views/partners/statistics/ManageStat.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    /**
     * 파트너스 - 정산
     */
    //지급 요율 관리
    {
      path: "/partners/calculation/rate",
      name: "ManageRate",
      component: () => import("@/views/partners/calculation/ManageRate.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    //정산관리
    {
      path: "/partners/calculation/cal",
      name: "ManageCal",
      component: () => import("@/views/partners/calculation/ManageCal.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/partners/calculation/cal/:id",
      name: "DetailCal",
      component: () => import("@/views/partners/calculation/DetailCal.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    /**
     * 파트너스 - 공지사항
     */
    {
      path: "/partners/notice",
      name: "ManageNotice",
      component: () => import("@/views/partners/notice/ManageNotice.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/partners/notice/detail/:id",
      name: "DetailNotice",
      component: () => import("@/views/partners/notice/DetailNotice.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/partners/notice/register",
      name: "RegisterNotice",
      component: () => import("@/views/partners/notice/RegisterNotice.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    /**
     * 파트너스 - FAQ
     */
    {
      path: "/partners/faq",
      name: "ManageFaq",
      component: () => import("@/views/partners/faq/ManageFaq.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/partners/faq/detail/:id",
      name: "DetailFaq",
      component: () => import("@/views/partners/faq/DetailFaq.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/partners/faq/register",
      name: "RegisterFaq",
      component: () => import("@/views/partners/faq/RegisterFaq.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    /**
     * 파트너스 - 알림
     */
    {
      path: "/partners/alert",
      name: "PartnersAlert",
      component: () => import("@/views/partners/PartnersAlert.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.getters["auth/authenticated"]) {
      return next({ name: "login" });
    } else {
      next();
    }
  }
  next();
});

export default router;
