import store from "@/store";
import router from "@/router";

export function setPartnersInterceptors(instance) {
  // 요청 인터셉터 추가
  instance.interceptors.request.use(
    function (config) {
      // console.log(config, "config before");
      // 요청 보내기 전에 수행할 일
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "partners_token"
      )}`;
      return config;
    },
    function (error) {
      // console.log(error, "error before");
      // 오류 요청을 보내기 전 수행할 일
      return Promise.reject(error);
    }
  );

  // 응답 인터셉터 추가
  instance.interceptors.response.use(
    function (response) {
      // console.log(response, "response after");
      // 응답 데이터 가공
      return response;
    },
    function (error) {
      // console.log(error, "partners error after");
      if (
        error.response.status == 401 &&
        router.currentRoute.name !== "LoginView"
      ) {
        store.dispatch("auth/logout");
        router.push("/login").catch(() => {});
      }
      // 오류 응답 처리
      return Promise.reject(error);
    }
  );

  return instance;
}
