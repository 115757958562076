/**
 * 팅서치 메뉴
 */
//팅서치 메뉴
export const tingsearchMenu = [
  {
    title: "유저관리",
    icon: "mdi-account-details-outline",
    sub: [
      {
        title: "유저리스트",
        icon: "mdi-credit-card-outline",
        path: "/tingsearch/user/all",
      },
      {
        title: "해지리스트",
        icon: "mdi-laptop",
        path: "/tingsearch/user/delete-plan",
      },
      {
        title: "탈퇴리스트",
        icon: "mdi-feature-search-outline",
        path: "/tingsearch/user/delete-account",
      },
    ],
  },
  {
    title: "통계",
    icon: "mdi-chart-bar",
    sub: [
      {
        title: "매출통계",
        icon: "mdi-credit-card-outline",
        path: "/tingsearch/stat/sales",
      },
      {
        title: "접속통계",
        icon: "mdi-laptop",
        path: "/tingsearch/stat/access",
      },
      {
        title: "검색키워드통계",
        icon: "mdi-feature-search-outline",
        path: "/tingsearch/stat/keyword/search",
      },
      {
        title: "뉴스키워드통계",
        icon: "mdi-feature-search-outline",
        path: "/tingsearch/stat/keyword/news",
      },
    ],
  },
];

//이전버전
/** 
export const tingsearchMenu_ori = [
  {
    title: "대시보드",
    icon: "mdi-home",
    path: "/",
  },
  {
    title: "유저목록",
    icon: "mdi-account-details-outline",
    path: "/tingsearch/user",
  },
];

export const tingsearchStatMenu_ori = [
  {
    title: "통계",
    icon: "mdi-chart-bar",
    sub: [
      {
        title: "매출통계",
        icon: "mdi-credit-card-outline",
        path: "/tingsearch/stat/sales",
      },
      {
        title: "접속통계",
        icon: "mdi-laptop",
        path: "/tingsearch/stat/access",
      },
      {
        title: "검색키워드통계",
        icon: "mdi-feature-search-outline",
        path: "/tingsearch/stat/keyword/search",
      },
      {
        title: "뉴스키워드통계",
        icon: "mdi-feature-search-outline",
        path: "/tingsearch/stat/keyword/news",
      },
    ],
  },
];
*/

/**
 * 파트너스 메뉴
 */
//파트너스
export const partnersListMenu = [
  {
    title: "파트너스",
    icon: "mdi-account-details-outline",
    sub: [
      {
        title: "파트너 리스트",
        icon: "mdi-credit-card-outline",
        path: "/partners/member/list",
      },
      {
        title: "링크관리",
        icon: "mdi-credit-card-outline",
        path: "/partners/member/link",
      },
    ],
  },
];

//파트너스 정산
export const partnersCalcMenu = [
  {
    title: "정산",
    icon: "mdi-cash",
    sub: [
      {
        title: "지급 요율 관리",
        icon: "mdi-credit-card-outline",
        path: "/partners/calculation/rate",
      },
      {
        title: "정산관리",
        icon: "mdi-credit-card-outline",
        path: "/partners/calculation/cal",
      },
    ],
  },
];

//이전 버전
/** 
export const partnersMenu_ori = [
  {
    title: "파트너스",
    icon: "mdi-account-details-outline",
    sub: [
      {
        title: "파트너 리스트",
        icon: "mdi-credit-card-outline",
        path: "/partners/member/list",
      },
      {
        title: "링크관리",
        icon: "mdi-credit-card-outline",
        path: "/partners/member/link",
      },
    ],
  },
  {
    title: "통계",
    icon: "mdi-chart-bar",
    sub: [
      {
        title: "통계관리",
        icon: "mdi-credit-card-outline",
        path: "/partners/statistics/manage",
      },
    ],
  },
  {
    title: "정산",
    icon: "mdi-cash",
    sub: [
      {
        title: "지급 요율 관리",
        icon: "mdi-credit-card-outline",
        path: "/partners/calculation/rate",
      },
      {
        title: "정산관리",
        icon: "mdi-credit-card-outline",
        path: "/partners/calculation/cal",
      },
    ],
  },
  {
    title: "공지사항",
    icon: "mdi-bulletin-board",
    sub: [
      {
        title: "공지사항",
        icon: "mdi-credit-card-outline",
        path: "/partners/notice",
      },
    ],
  },
];
*/
