export default {
  namespaced: true,

  state: {
    active: false,
    message: "",
  },

  getters: {
    active(state) {
      return state.active;
    },

    activeMessage(state) {
      return state.message;
    },
  },

  mutations: {
    setMsg(state, payload) {
      state.message = payload;
      state.active = true;
      setTimeout(() => {
        state.message = "";
        state.active = false;
      }, 2000);
    },
  },
};
