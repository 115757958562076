import axios from "axios";
import { setTingsearchInterceptors } from "./tingsearchInterceptors";

function createInstance() {
  const instance = axios.create({
    // baseURL: "https://dev.api.tingsearch.com/api/v2/staff",
    baseURL: process.env.VUE_APP_API_URL,
  });
  return setTingsearchInterceptors(instance);
}
const tingsearchInstance = createInstance();

//로그인
export function loginTingsearch(data) {
  return tingsearchInstance.post("sign", data);
}
//새로고침 시
export function fetchTingsearchUser() {
  return tingsearchInstance.get("me");
}

/**
 * 대시보드
 */
//유저 정보 조회
export function getDashboard() {
  return tingsearchInstance.get("dashboard");
}
//네이버 api 사용량 조회
export function getNaverApiUsage(params) {
  return tingsearchInstance.get("naver-key/summary", params);
}

/**
 * 유저관리 - 유저리스트
 */
//유저 목록 조회
export function getAllUserList(params) {
  return tingsearchInstance.get("users", params);
}
//유저 생성
export function createUser(params) {
  return tingsearchInstance.post("users", params);
}
//유저 수정
export function editUser(id, params) {
  return tingsearchInstance.patch(`users/${id}`, params);
}
//유저 삭제
export function removeUser(id) {
  return tingsearchInstance.delete(`users/${id}`);
}
//멤버쉽 플랜 추가
export function addMembership(id, params) {
  return tingsearchInstance.post(`users/${id}/subscribe`, params);
}
//멤버쉽 플랜 변경
export function changeMembership(id, params) {
  return tingsearchInstance.patch(`users/${id}/subscribe`, params);
}
//멤버쉽 삭제
export function removeMembership(id) {
  return tingsearchInstance.delete(`users/${id}/subscribe`);
}
//플랜 삭제
export function removePlan(planId) {
  return tingsearchInstance.delete(`users/enterprise/revoke/${planId}`);
}

/**
 * 유저관리 - 해지리스트
 */
//해지리스트 목록 조회
export function getDeletePlanReaosonList(params) {
  return tingsearchInstance.get("reasons/subscription", params);
}

/**
 * 유저관리 - 탈퇴리스트
 */
//탈퇴리스트 목록 조회
export function getDeleteAccountReasonList(params) {
  return tingsearchInstance.get("reasons/user", params);
}

/**
 * 통계 - 매출통계
 */
//매출통계 목록 조회
export function getSalesStat(params) {
  return tingsearchInstance.get("paid", params);
}

/**
 * 통계 - 접속통계
 */
//접속통계 목록 조회
export function getAccessStat(params) {
  return tingsearchInstance.get("access", params);
}

/**
 * 통계 - 검색키워드통계
 */
//검색키워드통계 목록 조회
export function getSearchKeywordStat(params) {
  return tingsearchInstance.get("keywords", params);
}

/**
 * 통계 - 뉴스키워드통계
 */
//뉴스키워드통계 목록 조회
export function getNewsKeywordStat(params) {
  return tingsearchInstance.get("keywords-news", params);
}

/**
 * 가맹사업 정보
 */
//가맹사업 정보 조회
export function getFranchiseList(params) {
  return tingsearchInstance.get("franchise", params);
}
