<template>
  <v-app>
    <!-- navigation-drawer -->
    <v-navigation-drawer
      v-if="authenticated"
      v-model="drawerActive"
      app
      floating
      class="the_nav px-2"
    >
      <v-list-item class="py-2">
        <v-list-item-content>
          <div class="d-flex">
            <v-img
              src="@/assets/T-search_Logo-typo.svg"
              transition="scale-transition"
              contain
              class="shrink"
            />
          </div>
        </v-list-item-content>
      </v-list-item>

      <!-- 팅서치 메뉴 -->
      <v-list rounded dense>
        <v-subheader class="dark--text font-weight-bold">팅서치</v-subheader>
        <!-- 팅서치 : 대시보드 -->
        <v-list-item-group color="primary">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>대시보드</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <!-- 팅서치 : 유저, 통계 -->
        <v-list-group
          v-for="item in tingsearchMenu"
          :key="item.title"
          :value="false"
          :prepend-icon="item.icon"
          no-action
          color="primary"
          class="mt-2"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="subItem in item.sub"
            :key="subItem.title"
            :to="subItem.path"
          >
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- 팅서치 : 가맹점 -->
        <!-- <v-list-item-group color="primary">
          <v-list-item to="/tingsearch/franchise">
            <v-list-item-icon>
              <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-title>가맹사업 정보</v-list-item-title>
          </v-list-item>
        </v-list-item-group> -->
      </v-list>

      <!-- 파트너스 메뉴 -->
      <v-list rounded dense>
        <v-subheader class="dark--text font-weight-bold">파트너스</v-subheader>
        <!-- 파트너스 : 파트너스 -->
        <v-list-group
          v-for="item in partnersListMenu"
          :key="item.title"
          :value="false"
          :prepend-icon="item.icon"
          no-action
          color="primary"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="subItem in item.sub"
            :key="subItem.title"
            :to="subItem.path"
          >
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- 파트너스 : 통계(단일) -->
        <v-list-item-group color="primary">
          <v-list-item to="/partners/statistics/manage">
            <v-list-item-icon>
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>통계관리</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <!-- 파트너스 : 정산 -->
        <v-list-group
          v-for="item in partnersCalcMenu"
          :key="item.title"
          :value="false"
          :prepend-icon="item.icon"
          no-action
          color="primary"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="subItem in item.sub"
            :key="subItem.title"
            :to="subItem.path"
          >
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- 파트너스 : 공지사항(단일) -->
        <v-list-item-group color="primary">
          <v-list-item to="/partners/notice">
            <v-list-item-icon>
              <v-icon>mdi-bulletin-board</v-icon>
            </v-list-item-icon>
            <v-list-item-title>공지사항</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <!-- 파트너스 : FAQ(단일) -->
        <v-list-item-group color="primary">
          <v-list-item to="/partners/faq">
            <v-list-item-icon>
              <v-icon>mdi-frequently-asked-questions</v-icon>
            </v-list-item-icon>
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- app-bar -->
    <v-app-bar v-if="authenticated" app class="the_header">
      <v-app-bar-nav-icon
        color="primary"
        @click.stop="drawerActive = !drawerActive"
      />
      <v-spacer />

      <v-menu offset-y>
        <!-- 프로필 메뉴 -->
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon v-if="!partnersUser?.unchecked_alert_count" color="primary"
              >mdi-cog</v-icon
            >
            <v-badge
              v-else
              dot
              bordered
              color="red"
              offset-x="20"
              offset-y="20"
            >
              <v-avatar>
                <v-icon color="primary">mdi-cog</v-icon>
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item>
            <v-list-item-avatar color="primary" size="45">
              <span class="white--text headline">
                <!-- {{ user.name.slice(0, 1) }} -->
                {{ user.name }}
              </span>
            </v-list-item-avatar>
            <v-list-item-content class="dark--text">
              <v-list-item-title style="font-size: 15px">
                <span class="font-weight-bold">{{ user.name }} 님</span>
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-2" />
          <!-- 로그아웃 -->
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon color="primary">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="dark--text">로그아웃</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- 파트너스 알림 -->
          <v-list-item @click="moveToAlert">
            <v-list-item-icon>
              <v-icon
                v-if="!partnersUser?.unchecked_alert_count"
                color="primary"
              >
                mdi-bell-outline</v-icon
              >
              <v-badge
                v-else
                dot
                bordered
                color="red"
                offset-x="10"
                offset-y="10"
              >
                <v-icon color="primary">mdi-bell-outline</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="dark--text">
                파트너스 알림
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- main -->
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>

    <!-- footer -->
    <v-footer app padless color="secondary">
      <v-col class="text-right" cols="12">
        <strong>© TDI {{ new Date().getFullYear() }}</strong>
      </v-col>
    </v-footer>

    <AlertBar />
  </v-app>
</template>

<script>
import {
  tingsearchMenu,
  // tingsearchStatMenu,
  // partnersMenu,
  partnersListMenu,
  partnersCalcMenu,
} from "@/plugins/menu";

import AlertBar from "@/components/AlertBar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: { AlertBar },

  data: () => ({
    drawerActive: true,

    tingsearchMenu,
    // tingsearchStatMenu,
    // partnersMenu,
    partnersListMenu,
    partnersCalcMenu,
  }),

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      partnersUser: "auth/partnersUser",
    }),
  },

  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),

    logout() {
      this.logoutAction();
      this.$router.push("/login").catch(() => {});
    },

    moveToAlert() {
      this.$router.push({ name: "PartnersAlert" }).catch(() => {});
    },
  },
};
</script>

<style>
.the_header {
  background-color: #f7f9fb !important;
  box-shadow: 0 12px 12px 0 rgb(0 65 162 / 6%) !important;
  z-index: 99 !important;
}
.the_nav {
  background-color: #f7f9fb !important;
  box-shadow: 0 20px 20px 12px rgb(0 65 162 / 6%);
  border-end-end-radius: 100px;
}
</style>
