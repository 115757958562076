import axios from "axios";
import { setPartnersInterceptors } from "./partnersInterceptors";

function createInstance() {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_PARTNER_URL,
  });
  return setPartnersInterceptors(instance);
}
const partnersInstance = createInstance();

//새로고침 시 유저 정보
export function fetchPartnersUser() {
  return partnersInstance.get("me");
}

/**
 * 파트너스
 */
//파트너 목록
export function fetchUserList(params) {
  return partnersInstance.get("admin/user", params);
}
//파트너 삭제
export function deleteUser(params) {
  return partnersInstance.delete("admin/user", params);
}
// 파트너 리스트 엑셀 다운로드
export function exportUserListExcel(params) {
  return axios({
    baseURL: process.env.VUE_APP_PARTNER_URL + "/admin/user-export",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("partners_token")}`,
    },
    methods: "GET",
    responseType: "blob",
    params,
  });
}

//파트너 상세 조회
export function fetchUserDetail(id) {
  return partnersInstance.get(`admin/user/${id}`);
}
//파트너 상세 신분증 승인
export function proceedApproveAndRejectIdcard(id, params) {
  return partnersInstance.patch(`admin/idcardAttempts/${id}`, params);
}
//파트너 상세 사업자등록증 승인
export function proceedApproveAndRejectBizReg(id, params) {
  return partnersInstance.patch(`admin/bizAttempts/${id}`, params);
}
//파트너 상세 통장사본 승인
export function proceedApproveAndRejectBankbook(id, params) {
  return partnersInstance.patch(`admin/bnkAttempts/${id}`, params);
}
//파트너 상세 전체 승인
export function proceedApproveAndRejectUser(id, params) {
  return partnersInstance.patch(`admin/user/${id}/status`, params);
}
//파트너 상세 커미션 정산요율 조회
export function fetchUserDetailCalcRate(params) {
  return partnersInstance.get("admin/calcRate/user", params);
}
//파트너 상세 커미션 개별 요율 변경
export function editUserPerRate(params) {
  return partnersInstance.post("admin/calcRates-personal", params);
}

//파트너 통계내역 조회
export function fetchUserStat(params) {
  return partnersInstance.get("admin/statistics", params);
}
//파트너 통계내역 엑셀 다운로드
export function exportUserStatExcel(params) {
  return axios({
    baseURL: process.env.VUE_APP_PARTNER_URL + "/admin/statistics-export",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("partners_token")}`,
    },
    methods: "GET",
    responseType: "blob",
    params,
  });
}

/**
 * 통계 - 통계관리
 */
//통계관리 조회
export function fetchTotalStatList(params) {
  return partnersInstance.get("admin/statistics-total", params);
}
//통계관리 엑셀 다운로드
export function exportTotalStatExcel(params) {
  return axios({
    baseURL: process.env.VUE_APP_PARTNER_URL + "/admin/statistics-total-export",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("partners_token")}`,
    },
    methods: "GET",
    responseType: "blob",
    params,
  });
}

/**
 * 정산 - 지급 요율 관리
 */
//정산요율 조회
export function fetchRate() {
  return partnersInstance.get("admin/calcRates-global");
}
//정산요율 생성
export function createBaseRate(params) {
  return partnersInstance.post("admin/calcRates-global", params);
}

/**
 * 정산 - 정산관리
 */
//정산관리 기록 조회
export function fetchCalcList(params) {
  return partnersInstance.get("admin/mthCalcLog", params);
}
//정산관리 개별 기록 조회
export function fetchUserCalcDetail(id) {
  return partnersInstance.get(`admin/mthCalcLog/${id}`);
}
//정산관리 엑셀 다운로드
export function exportCalcExcel(params) {
  return axios({
    baseURL: process.env.VUE_APP_PARTNER_URL + "/admin/mthCalcLog-export",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("partners_token")}`,
    },
    methods: "GET",
    responseType: "blob",
    params,
  });
}

//정산 기록 유저별 조회 (삭제예정)
export function fetchUserCalc(id) {
  return partnersInstance.get(`admin/users/${id}/mthCalcLog`);
}
//정산 내역 수정 (삭제예정)
export function editUserCalcDetail(id, params) {
  return partnersInstance.patch(`admin/mthCalcLog/${id}/settleStatus`, params);
}
/** */
/** */
/** */
//new ver
//정산 - 정산 상태 수정
export function editCalcSettlement(id, params) {
  return partnersInstance.patch(`admin/mthCalcLog/${id}/settleStatus`, params);
}
//정산 - 지급 상태 수정
export function editCalcPayment(id, params) {
  return partnersInstance.patch(`admin/mthCalcLog/${id}/paymentStatus`, params);
}
//정산 - 세금 상태 수정
export function editCalcTaxBill(id, params) {
  return partnersInstance.patch(`admin/mthCalcLog/${id}/taxBillStatus`, params);
}
// //유저 상세 조회
// export function fetchCalcDetail(id) {
//   return partnersInstance.get(`admin/mthCalcLog/${id}`);
// }
/** */
/** */
/** */

/**
 * 공지사항
 */
//공지사항 목록
export function fetchNoticeList(params) {
  return partnersInstance.get("admin/notices", params);
}
//공지사항 생성
export function createNotice(params) {
  return partnersInstance.post("admin/notices", params);
}
//공지사항 조회
export function fetchNoticeDetail(id) {
  return partnersInstance.get(`admin/notices/${id}`);
}
//공지사항 수정
export function editNotice(id, params) {
  return partnersInstance.put(`admin/notices/${id}`, params);
}
//공지사항 삭제
export function deleteNotice(params) {
  return partnersInstance.delete("admin/notices", params);
}

/**
 * Faq
 */
//Faq 목록
export function fetchFaqList(params) {
  return partnersInstance.get("admin/faqs", params);
}
//Faq 생성
export function createFaq(params) {
  return partnersInstance.post("admin/faqs", params);
}
//Faq 조회
export function fetchFaqDetail(id) {
  return partnersInstance.get(`admin/faqs/${id}`);
}
//Faq 수정
export function editFaq(id, params) {
  return partnersInstance.put(`admin/faqs/${id}`, params);
}
//Faq 삭제
export function deleteFaq(params) {
  return partnersInstance.delete("admin/faqs", params);
}

/**
 * 파트너스 알림
 */
//알림 목록
export function fetchPartnersAlert(params) {
  return partnersInstance.get("admin/alerts", params);
}
