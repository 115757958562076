import { fetchTingsearchUser } from "@/api/tingsearchApi";
import { fetchPartnersUser } from "@/api/partnersApi";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,

    partnersUser: null,
  },

  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },

    partnersUser(state) {
      return state.partnersUser;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },

    SET_PARTNERS_USER(state, payload) {
      state.partnersUser = payload;
    },
  },

  actions: {
    async attempt({ commit, dispatch }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }
      // if (!state.token) {
      //   return;
      // }
      try {
        const response = await fetchTingsearchUser();
        commit("SET_USER", response.data);

        dispatch("partnersAttempt");
      } catch (error) {
        dispatch("logout");
      }
    },

    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
      localStorage.removeItem("token", null);
      localStorage.removeItem("partners_token", null);
    },

    async partnersAttempt({ commit }) {
      try {
        const response = await fetchPartnersUser();
        commit("SET_PARTNERS_USER", response.data.user);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
